import * as React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby';

import { DefaultLayout } from '../components/layouts/default-layout';
import HomePageEditor from "../components/pages/home-page/home-page-editor";
import HelloBlock from "../components/pages/home-page/hello-block";
import { ProjectBlock, ProjectData } from "../components/pages/webdev/project-block";

const layoutWidth = 1280;
const layoutPadding = '1em';
const Wrapper = styled.div`
    /* > * + * {
        margin-top: 1em;
    } */
`;
const ContentBlock = styled.div`
    padding: 0 ${layoutPadding};
    max-width: ${layoutWidth}px;
    margin: 0 auto;
`;
// @todo DRY
const TextBlock = styled.div`
    /* text-align: justify; */

    &:after {
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    > * + * {
        margin-top: 1em;
    }

    > * + h3 {
        margin-top: 2em;
    }

    h3 {
        color: rgba(0, 0, 0, .5);
        font-size: .75em;
        line-height: 1;
        padding-bottom: .25em;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        a {
        }
    }

    p, ul {
        color: rgba(0, 0, 0, .5);
        font-size: .6em;
        line-height: 1.5;
    }

    ul {
        list-style: circle;
        padding-left: 1em;
    }

    a {
        border-bottom: 1px solid dodgerblue;

        &:hover {
            color: dodgerblue;
            /* background-color: rgba(0, 0, 0, .5); */
        }
    }

    strong {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    kbd {
        background-color: #eee;
        font-family: 'Inconsolata', monospace;
    }
`;

const projects: ProjectData[] = [
    {
        name: 'media-tagger',
        images: [
            "/images/webdev/media-tagger-3.png",
            "/images/webdev/media-tagger-2.png",
            "/images/webdev/media-tagger-1.png",
        ],
        content: () => {
            return (
                <>
                    <p>I wanted to have a better way of managing my camera records, photos and various media files and I was not quite satisfied with existing programs.</p>

                    <p>Present functionality:</p>

                    <ul>
                        <li>tagging - assign tags to individual files, add tags to multiple files and remove tags from multiple files</li>
                        <li>filtering media files by assigned tags and metadata (file size, image/video dimensions, modification date)</li>
                        <li>configurable grid view</li>
                        <li>separate tabs with their own filtering, sorting, pagination and view settings</li>
                    </ul>

                    <p>Planned features:</p>

                    <ul>
                        <li>creating media file lists with custom ordering</li>
                        <li>batch image/video operations - resize, crop, optimize</li>
                    </ul>

                    <p>Tech stack: TypeScript, React, Express</p>
                </>
            );
        },
    },
    {
        name: 'habits-app',
        images: [
            "/images/webdev/habits-app-1.png",
            "/images/webdev/habits-app-2.png",
        ],
        content: () => {
            const projectPath = `/apps/habits-app`;

            return (
                <>
                    <p>This is my take on task management in which the user doesn't focus on individual tasks as much as on habits or <i>recurring</i> tasks.</p>
                    <p>Tasks are managed using a single text area which makes the app similar to a text editor. Every task is put in a separate line, it contains its name, the time period of recurrence and the most recent date of finishing the task, e.g. <kbd>foo bar $d=5 2/15</kbd> indicates that you should do the "foo bar" task every 5 days and that you finished it most recently on February 15. The greatest thing about the app is the visual help in the form of highlighted task names which are based on their time periods and the overdue time - all that lets you find out your priorities easier. Finally a solution to a personal overwhelm flowing from busy life and too many ambitious goals?</p>
                    <p>Currently entering the data may be at times cumbersome; I could use date pickers and other controls to make it easier and eventually implement drag'n'drop for the tasks but that could impair the simplicity of both the functionality and the code, and most importantly, set the app for a different, more broad road, instead of the current focused approach.</p>

                    <p>See the app here: <a href={projectPath} target="_blank">{projectPath}</a> (data you enter is stored in in your browser)</p>

                    <p>Tech stack: TypeScript, React</p>
                </>
            );
        },
    },
    {
        name: 'image-grid',
        images: [
            "/images/webdev/image-grid-1.png",
            "/images/webdev/image-grid-2.png",
        ],
        content: () => {
            return (
                <>
                    <p>An experiment in the which user-given images are sorted on a grid according to their color information combined with randomly generated noise matrix, creating interesting compositions out of seemingly generic series of photos.</p>

                    <p>Tech stack: TypeScript, React, Express</p>
                </>
            );
        },
    },
    {
        name: 'lang-app',
        images: [
            "/images/webdev/lang-app-1.png",
            "/images/webdev/lang-app-2.png",
            "/images/webdev/lang-app-3.png",
        ],
        content: () => {
            return (
                <>
                    <p>This app emerged from a bunch of small vanilla JavaScript functions that I'd intended to help me with learning new languages while using Duolingo, into a separate app combining all the functionality across a few languages, like Spanish and German.</p>
                    <p>My problem with Duolingo is that as much as it provides general lessons that aim to imitate real life situations, it lacks exercises with a narrow focus on a specific thing to cover the broad spectrum of language knowledge, like verb conjugation (Spanish), noun/adjective declension (German) or extensive vocabulary revision based on specific word groups.</p>
                    <p>Nice thing about this app is that I can define custom word groups, add the words by hand and revise them separately, e.g. after reading a book or an article that included many words previously not known to me. The app remembers the words I practiced and when it prompts me with new words it generally prioritizes the least recently practiced ones.</p>
                    <p>Tech stack: TypeScript, React</p>
                    <p>Note for the aesthetically-oriented: while developing this app I focused solely on the functionality and disregarded the looks for the time being - the screenshots below are only indicative.</p>
                </>
            );
        },
    },
    {
        name: 'music-trainer',
        images: [
            '/images/webdev/music-trainer-4.png',
            '/images/webdev/music-trainer-5.png',
            '/images/webdev/music-trainer-2.png',
        ],
        content: () => {
            const projectPath = `/apps/music-theory`;

            return (
                <>
                    <p>An experiment aiming at helping to learn music theory and to practice instruments in general, with focus on piano and guitar.</p>
                    <p>The problem with a kind of educational types of apps that aim to scratch my own itch is that I tend to end up spending more time working on them than actually working on the underlying problem they're trying to solve in real life. In this case, after spending some time of development, I went back to learning piano intensively and I kind of abandoned the project.</p>
                    <p>All the graphics (the fretboard and the keyboard diagrams) are made by me.</p>

                    <p>See the app here: <a href={projectPath} target="_blank">{projectPath}</a> - this is a version limited to music theory, I've also worked on interactive exercises that I may show in the future.</p>

                    <p>Tech stack: TypeScript, React</p>
                </>
            );
        },
    },
    {
        name: 'madfractal.com',
        // link: 'https://madfractal.com',
        images: [
            "/images/webdev/madfractal-website-1.png",
            "/images/webdev/madfractal-website-2.png",
        ],
        content: () => {
            return <>
                <p>A website for my game Madfractal. Created with Gatsby.</p>

                <p>Visit here: <a href="https://madfractal.com" target="_blank">madfractal.com</a></p>
            </>
        },
    },
    {
        name: 'this website',
        images: [],
        content: () => {
            return <p>Created with Gatsby.</p>
        },
    },
]

export default function Webdev() {
    return <DefaultLayout>
        <Wrapper>
            <div style={{ backgroundColor: '#fff' }}>
                <ContentBlock>
                    <div style={{ padding: '3em 0' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <HelloBlock />
                        </div>
                    </div>
                </ContentBlock>
            </div>

            <div>
                {/* <div style={{border: '0 solid #000', borderWidth: '2em 0'}}> */}
                <HomePageEditor extended={true} />
                {/* </div> */}

                <div style={{height: 1, backgroundColor: '#fff'}}></div>

                <div style={{ backgroundColor: `hsl(240, 25%, 95%)`, padding: '2em 0' }}>
                    <ContentBlock>
                        <div style={{ backgroundColor: '#fff', padding: '2em' }}>
                            <TextBlock>
                                <h2>Personal projects (work-in-progress)</h2>

                                <p>Most of the projects below are the result of tinkering and scratching my own itch and as such are not yet mature enough to be released for other people to use. In case I do provide a link for a web-app, please expect that occasional bugs may be present and things may not be as slick as they should be in the end, especially regarding the compatibility with handheld devices as I personally use desktop only and most often I postpone things like RWD and accessibility until the later stage of the project, when I know it's worth investing more time in it.</p>

                                {
                                    projects.map(project => {
                                        return (
                                            <ProjectBlock key={project.name} project={project} />
                                        )
                                    })
                                }
                            </TextBlock>
                        </div>
                    </ContentBlock>
                </div>
            </div>
        </Wrapper>
    </DefaultLayout>
}
