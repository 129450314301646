import * as React from "react";
import styled from "@emotion/styled";

export type ProjectData = {
    name: string;
    link?: string;
    images: string[];
    content?: React.FC;
};

export const ProjectBlock: React.FC<{ project: ProjectData }> = props => {
    const { project } = props;
    const { content: Content } = project;

    const [activeImage, setActiveImage] = React.useState(project.images[0]);

    return (
        <>
            {
                project.link
                    ?
                    <h3><a href={project.link}>{project.name}</a></h3>
                    :
                    <h3>{project.name}</h3>
            }

            {
                Content
                &&
                <Content />
            }

            {
                project.images.length > 0
                &&
                <div>
                    <div style={{ backgroundColor: "#000" }}>
                        <a href={activeImage} target="_blank" style={{ display: 'flex', alignItems: 'center', minHeight: 400, cursor: "zoom-in", border: 0 }}>
                            <img
                                src={activeImage}
                                loading="lazy"
                                style={{
                                    maxHeight: 500,
                                    maxWidth: '100%',
                                    display: 'block',
                                    margin: '0 auto',
                                }}
                            />
                        </a>
                    </div>

                    {
                        project.images.length > 1
                        &&
                        <div style={{
                            backgroundColor: '#eee',
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                        }}>
                            {
                                project.images.map(img => {
                                    const isActive = img === activeImage;

                                    return (
                                        <img
                                            key={img}
                                            src={img}
                                            loading="lazy"
                                            style={{
                                                cursor: 'pointer',
                                                maxHeight: 100,
                                                border: '.1em solid',
                                                borderColor: isActive ? 'dodgerblue' : 'transparent',
                                            }}
                                            onClick={() => {
                                                setActiveImage(img);
                                            }}
                                        />
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
};